import React from 'react';

const IconUpwork = () => (
  <svg
    viewBox="-2 -2 36 36"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather"
  >
    <title>Upwork</title>
    <path d="M24.75 17.542c-1.469 0-2.849-.62-4.099-1.635l.302-1.432.01-.057c.276-1.521 1.13-4.078 3.786-4.078a3.605 3.605 0 013.604 3.604 3.605 3.605 0 01-3.604 3.599zm0-10.849c-3.385 0-6.016 2.198-7.083 5.818-1.625-2.443-2.865-5.38-3.583-7.854h-3.646v9.484a3.403 3.403 0 01-3.396 3.396 3.406 3.406 0 01-3.396-3.396V4.657H0v9.484c0 3.885 3.161 7.068 7.042 7.068 3.885 0 7.042-3.182 7.042-7.068v-1.589c.708 1.474 1.578 2.974 2.635 4.297l-2.234 10.495h3.729l1.62-7.615c1.417.906 3.047 1.479 4.917 1.479 4 0 7.25-3.271 7.25-7.266 0-4-3.25-7.25-7.25-7.25z"></path>
  </svg>
);

export default IconUpwork;
